<style lang="scss"></style>

<template>
  <issue-main :fields="fields" :taskId="taskId" :issueName="name">
    <div class="issue-main__title">Airport</div>
    <p>
      Дана база пассажиропотока в некотором аэропорту “Сайнс” с января 2007 г.
      по 2018 гг. в файле Science.csv
    </p>
    <button-download :file="`${taskId}.zip`" size="2 Kb" class="mb-12" />
    <p>
      Необходимо написать модель для предсказания пассажиропотока и предсказать
      пассажиропоток в последующие 12 месяцев. Ответ выведите в формате JSON
    </p>
    <p class="font-bold">Мерой точности построенной модели является MSE не более 520000.0 от реальных значений</p>

    <div class="issue-main__subtitle issue-main__subtitle--primary mt-6">
      Ожидаемое значение
    </div>
    <div class="issue-main__code-block">
      <pre><code class="language-javascript" v-html="highlightExample"></code></pre>
    </div>
  </issue-main>
</template>

<script>
import IssueMain from '../IssueMain.vue'
import FileInput from '../FileInput.vue'
import IconDownload from '../Icons/IconDownload.vue'
import useGame from '../../composables/useGame'
import Prism from 'prismjs'
import ButtonDownload from '../ButtonDownload.vue'

export default {
  name: 'IssueAirport',
  components: { IssueMain, FileInput, IconDownload, ButtonDownload },
  data() {
    return {
      name: 'airport',
      fields: [
        { name: 'Passengers', comment: 'пассажиропоток' },
        { name: 'SYear', comment: 'год' },
        { name: 'SMonth', comment: 'месяц' },
      ],
      example: `
{
  "answer": ["x1", "x2", "x3", ... , "x5"]
}
      `,
    }
  },
  props: {
    taskId: { type: String },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {
    highlightExample() {
      return Prism.highlight(
        this.example,
        Prism.languages.javascript,
        'javascript'
      )
    },
  },
}
</script>
