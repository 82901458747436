import { ref, reactive, toRefs, computed, watch } from '@vue/composition-api'
import { sendIntention } from '../api/sendIntention'

const state = reactive({
  isLoading: false,
  error: null,
  success: null,
})

export default function useIntention() {
  const respondOffer = async (answer) => {
    state.error = null
    state.success = null
    state.isLoading = true
    try {
      const { data } = await sendIntention(answer)
      state.success = data.data
    } catch (e) {
      state.error = e
    } finally {
      state.isLoading = false
    }
  }

  return {
    ...toRefs(state),
    respondOffer,
  }
}
