<style lang="scss">
.file-input {
  @apply font-graphik_lv_regular w-max px-4 flex items-center cursor-pointer relative;
  border: 1px dashed rgba(255, 255, 255, 0.25);
  border-radius: 8px;
  height: 44px;
  font-size: 12px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.7);
  transition: 0.25s;
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
  & span {
    @apply pr-2;
  }
  & svg {
    @apply pl-2;
    border-left: 1px solid rgba(255, 255, 255, 0.4);
  }
  & input {
    display: none;
  }
  &__filename {
    // @apply absolute whitespace-nowrap overflow-hidden overflow-ellipsis;
    @apply absolute;
    width: 186px;
    left: calc(100% + 12px);
    font-size: 12px;
    line-height: 16px;
  }
}
</style>

<template>
  <label class="file-input">
    <input type="file" @change="onFileChange" />
    <span>Прикрепить файл</span><icon-upload />
    <div class="file-input__filename">
      {{ fileName || label ||'файл в формате CSV' }}
    </div>
  </label>
</template>

<script>
import IconUpload from './Icons/IconUpload.vue'
export default {
  components: { IconUpload },
  name: 'FileInput',
  data() {
    return {
      file: null,
    }
  },
  props: ['value', 'label'],
  created() {},
  mounted() {},
  methods: {
    onFileChange(e) {
      const files = e.target.files
      if (!files.length) return
      this.file = files[0]
      this.$emit('input', this.file)
    },
  },
  computed: {
    fileName() {
      return this.file?.name
    },
  },
}
</script>
