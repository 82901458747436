<style lang="scss"></style>

<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3334 7.33338H6.27343L8.47343 5.14004C8.59896 5.01451 8.66949 4.84424 8.66949 4.66671C8.66949 4.48917 8.59896 4.31891 8.47343 4.19338C8.34789 4.06784 8.17763 3.99731 8.00009 3.99731C7.82256 3.99731 7.6523 4.06784 7.52676 4.19338L4.19343 7.52671C4.13273 7.59011 4.08516 7.66487 4.05343 7.74671C3.98675 7.90902 3.98675 8.09107 4.05343 8.25338C4.08516 8.33521 4.13273 8.40997 4.19343 8.47338L7.52676 11.8067C7.58874 11.8692 7.66247 11.9188 7.74371 11.9526C7.82495 11.9865 7.91209 12.0039 8.00009 12.0039C8.0881 12.0039 8.17524 11.9865 8.25648 11.9526C8.33772 11.9188 8.41145 11.8692 8.47343 11.8067C8.53591 11.7447 8.58551 11.671 8.61935 11.5898C8.6532 11.5085 8.67063 11.4214 8.67063 11.3334C8.67063 11.2454 8.6532 11.1582 8.61935 11.077C8.58551 10.9958 8.53591 10.922 8.47343 10.86L6.27343 8.66671H11.3334C11.5102 8.66671 11.6798 8.59647 11.8048 8.47145C11.9299 8.34642 12.0001 8.17685 12.0001 8.00004C12.0001 7.82323 11.9299 7.65366 11.8048 7.52864C11.6798 7.40361 11.5102 7.33338 11.3334 7.33338Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconArrowLeft',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
