<style lang="scss">
.success-modal {
  &__content {
    @apply flex flex-col items-center justify-center;
    background-color: rgba(0, 0, 0, 0.88);
    background-image: url('/images/ellipse-blur.svg');
    height: 100vh;
    background-position: top center;
    background-repeat: repeat no-repeat;
  }
  &__title {
    @apply font-druk_text_cy;
    font-weight: 500;
    font-size: 74px;
    line-height: 74px;
    text-transform: uppercase;
    color: #ffffff;
  }
  &__subtitle {
    @apply font-graphik_lv_regular;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    max-width: 325px;
  }
}
</style>

<template>
  <div class="success-modal__content">
    <div class="success-modal__title">{{ title }}</div>
    <div class="success-modal__subtitle mt-4">
      {{ subtitle }}
    </div>
    <button
      class="btn-primary mt-6"
      @click="next('Issues')"
    >
      К списку заданий
    </button>
    <!-- <button
      class="btn-primary mt-6"
      @click="next(isComplete ? 'Results' : 'Issues')"
    >
      {{ isComplete ? 'К результатам' : 'Далее' }}
    </button>
    <button v-if="isComplete" class="btn-ghost mt-6" @click="next('Issues')">
      К списку заданий
    </button> -->
  </div>
</template>

<script>
import useGame from '@/composables/useGame'

export default {
  name: 'ModalContentSuccess',
  components: {},
  data() {
    return {
      name: 'success-modal',
    }
  },
  props: {
    title: { type: String },
    subtitle: {
      type: String,
    },
  },
  setup() {
    const { taskSolved } = useGame()
    return { taskSolved }
  },
  created() {},
  mounted() {},
  methods: {
    next(page) {
      this.$router.push({ name: page })
      this.$emit('close')
    },
  },
  computed: {
    isComplete() {
      return this.taskSolved === 2
    },
  },
}
</script>
