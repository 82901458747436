<style lang="scss"></style>

<template>
  <issue-main :fields="fields" :taskId="taskId" :issueName="name">
    <div class="issue-main__title">Covid-19</div>
    <p>
      Дана статистика заболеваемости коронавирусом за определенный промежуток в
      разных странах, записанная в файл covid-19cases.csv. Необходимо:
    </p>
    <ol>
      <li>
        Произвести очистку данных, убрав пустые столбцы и неинформативные строки
        (строки, выделенные для заметок for Notes)
      </li>
      <li>
        Определить статистику случаев заражения в разных частях света (столбец
        continent) за каждый месяц
      </li>
      <li>Определить корреляцию каждой части света с другой.</li>
      <li>
        Вывести название части света, которая меньше всего коррелирует с другими
      </li>
    </ol>
    <button-download :file="`${taskId}.zip`" size="2.3 Mb" class="mb-12" />
  </issue-main>
</template>

<script>
import IssueMain from '../IssueMain.vue'
import FileInput from '../FileInput.vue'
import IconDownload from '../Icons/IconDownload.vue'
import Prism from 'prismjs'
import ButtonDownload from '../ButtonDownload.vue'

export default {
  name: 'IssueCovid19_1',
  components: { IssueMain, FileInput, IconDownload, ButtonDownload },
  data() {
    return {
      name: 'covid19_1',
      fields: [
        { name: 'date', comment: 'Дата' },
        { name: 'continent', comment: 'Часть света' },
        { name: 'location', comment: 'Местоположение' },
        { name: 'total_cases', comment: 'Общее количество случаев' },
        { name: 'new_cases', comment: 'Новые случаи заражения' },
        {
          name: 'new_cases_smoothed',
          comment: 'Новые случаи заражения сглаженно',
        },
        { name: 'total_deaths', comment: 'Общее количество смертей' },
        { name: 'new_deaths', comment: 'Количество новых смертей' },
        {
          name: 'new_deaths_smoothed',
          comment: 'Количество смертей сглаженно',
        },
        {
          name: 'total_cases_per_million',
          comment: 'Число случаев заражения на миллион',
        },
        {
          name: 'new_cases_per_million',
          comment: 'Новые случаи заражения на миллион',
        },
        {
          name: 'new_cases_smoothed_per_million',
          comment: 'Новые случаи заражения на миллион сглаженно',
        },
        {
          name: 'total_deaths_per_million',
          comment: 'Число смертей на миллион',
        },
        {
          name: 'new_deaths_per_million',
          comment: 'Число новых смертей на миллион',
        },
        {
          name: 'new_deaths_smoothed_per_million',
          comment: 'Число новых смертей на миллион сглаженно',
        },
        { name: 'total_tests', comment: 'Число тестов' },
        { name: 'new_tests', comment: 'Число новых тестов' },
        {
          name: 'total_tests_per_thousand',
          comment: 'Количество тестов на тысячу',
        },
        {
          name: 'new_tests_per_thousand',
          comment: 'Количество новых тестов на тысячу',
        },
        {
          name: 'new_tests_smoothed',
          comment: 'Количество новых тестов сглаженно',
        },
        {
          name: 'new_tests_smoothed_per_thousand',
          comment: 'Количество новых тестов на тысячу сглаженно',
        },
        { name: 'tests_per_case', comment: 'Число тестов на случай заражения' },
        { name: 'positive_rate', comment: 'Число положительных результатов' },
        { name: 'tests_units', comment: 'Число единиц тестов' },
        { name: 'stringency_index', comment: 'Индекс строгости' },
        { name: 'population', comment: 'Население' },
        { name: 'population_density', comment: 'Плотность населения' },
        { name: 'median_age', comment: 'Средний возраст' },
        { name: 'aged_65_older', comment: 'Число людей старше 65' },
        { name: 'aged_70_older', comment: 'Число людей старше 70' },
        { name: 'gdp_per_capita', comment: 'ВВП на душу населения' },
        { name: 'extreme_poverty', comment: 'Крайняя бедность' },
        {
          name: 'cardiovasc_death_rate',
          comment: 'Уровень смертности от сердечно-сосудистых заболеваний',
        },
        {
          name: 'diabetes_prevalence',
          comment: 'Распространененность диабета',
        },
        { name: 'female_smokers', comment: 'Число курящих женщин' },
      ],
      example: `
{
  "answer": ["x1", "x2", "x3", ... , "x5"]
}
      `,
    }
  },
  props: {
    taskId: { type: String },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {
    highlightExample() {
      return Prism.highlight(
        this.example,
        Prism.languages.javascript,
        'javascript'
      )
    },
  },
}
</script>
