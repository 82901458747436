<style lang="scss">
.btn-download {
  @apply font-graphik_lv_regular flex items-center w-max relative;
  height: 40px;
  padding-left: 16px;
  padding-right: 113px;
  background: linear-gradient(0deg, #1a1a1a, #1a1a1a), #034b9f;
  border-radius: 4px;
  &__filename {
    font-size: 12px;
    line-height: 24px;
    color: #ffffff;
    margin-right: 7px;
  }
  &__size {
    font-size: 12px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.5);
  }
  &__btn {
    @apply absolute px-3 flex items-center font-graphik_lv_regular;
    top: 4px;
    right: 4px;
    font-size: 12px;
    line-height: 24px;
    color: #90caff;
    background: #005ecd;
    border-radius: 4px;
    height: 32px;
    &:hover {
      background: #2176db;
      color: #ffffff;
    }
    &:hover:active {
      background: #0f4789;
      color: #ffffff;
    }
    &.done {
      background: #23282e;
      color: #90caff;
    }
    &:focus {
      outline: none;
    }
  }
}
</style>

<template>
  <div class="btn-download">
    <span class="btn-download__filename">{{ file }}</span>
    <span class="btn-download__size">{{ size }}</span>
    <button
      class="btn-download__btn"
      :class="{ done: done }"
      @click="clickDownload"
    >
      Скачать <icon-download class="ml-1" />
    </button>
  </div>
</template>

<script>
import IconDownload from './Icons/IconDownload.vue'
import useGame from '@/composables/useGame'
import { ref } from '@vue/composition-api'

export default {
  name: 'ButtonDownload',
  components: { IconDownload },
  data() {
    return {}
  },
  props: {
    file: { type: String },
    fileLink: { type: String },
    size: { type: String },
    taskId: { type: String },
  },
  setup(props) {
    const { loadDataset } = useGame()
    const done = ref(false)

    const clickDownload = () => {
      loadDataset(props.taskId)
      done.value = true
      window.open(
        props.fileLink ||
          `${window.location.protocol}//${window.location.host}/dataset/${props.file}?1`,
        '_blank'
      )
    }
    return { clickDownload, done }
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
