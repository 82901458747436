<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2668 6.40039H7.46681C6.87771 6.40039 6.40015 6.87795 6.40015 7.46706V12.267C6.40015 12.8562 6.87771 13.3337 7.46681 13.3337H12.2668C12.8559 13.3337 13.3335 12.8562 13.3335 12.267V7.46706C13.3335 6.87795 12.8559 6.40039 12.2668 6.40039Z"
      stroke="currentColor"
      stroke-width="1.06667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.26662 9.60032H3.73329C3.45039 9.60032 3.17908 9.48794 2.97904 9.2879C2.77901 9.08786 2.66663 8.81655 2.66663 8.53365V3.73366C2.66663 3.45076 2.77901 3.17945 2.97904 2.97941C3.17908 2.77937 3.45039 2.66699 3.73329 2.66699H8.53329C8.81618 2.66699 9.08749 2.77937 9.28753 2.97941C9.48757 3.17945 9.59995 3.45076 9.59995 3.73366V4.26699"
      stroke="currentColor"
      stroke-width="1.06667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCopy',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
