<style lang="scss">
.issue-main {
  &__navigation-container {
    @apply px-5 py-4 flex justify-between items-center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  &__navigation {
    @apply font-graphik_lv_regular flex;
    font-size: 12px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.5);
    & svg {
      @apply mx-1 relative;
      top: 1px;
    }
    &:hover {
      color: rgba(255, 255, 255, 0.7);
    }
    &:hover:active {
      color: rgba(255, 255, 255, 1);
    }
  }
  &__content {
    @apply relative;
    padding: 32px 32px 25px 42px;
  }
  @apply text-left relative;
  background: linear-gradient(
    180deg,
    rgba(6, 6, 6, 0.56) 0%,
    rgba(22, 22, 22, 0.76) 100%
  );
  border: 1px solid #313131;
  border-radius: 18px;

  & p,
  li {
    @apply font-graphik_lv_regular mb-2;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    list-style: auto;
    &:last-of-type {
      @apply mb-6;
    }
  }
  &__code-block {
    @apply overflow-auto mb-6 relative;
    max-height: 397px;
    background: #1a1a1a;
    border-radius: 8px;
    padding: 0 16px 16px 16px;
    &__copy-button {
      @apply sticky top-2;
      left: 732px;
    }
  }
  &__tabs {
    @apply w-max mb-6;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    &-item {
      @apply inline-block font-druk_text_cy;
      padding-bottom: 5px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: rgba(255, 255, 255, 0.3);
      border-bottom: 2px solid transparent;
      margin-right: 32px;
      &:last-child {
        margin-right: 0;
      }
      &:focus {
        outline: none;
      }
      &.current {
        color: rgba(255, 255, 255, 1);
        border-bottom: 2px solid currentColor;
      }
    }
  }
  &__title {
    @apply font-druk_text_cy mb-6;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
  }
  &__subtitle {
    @apply font-druk_text_cy mb-4;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: rgba(255, 255, 255, 1);
    &--primary {
      color: rgba(255, 255, 255, 1);
    }
  }
  &__data {
    @apply mt-6 w-max;
    min-width: 333px;
    max-width: 100%;
    border: 1px solid #222528;
    border-radius: 8px;
    display: block;
    & thead {
      @apply font-druk_text_cy;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.6);
      & td {
        height: 39px;
      }
    }
    & tbody {
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
      & td {
        @apply font-graphik_lv_regular;
        height: 52px;
        &:first-child {
          @apply font-graphik_lv_bold;
        }
      }
    }
    & td {
      @apply px-4 py-3;
      border-bottom: 1px solid #222528;
      &:first-child {
        width: 102px;
        border-right: 1px solid #222528;
      }
      &:last-child {
        @apply w-full;
      }
    }
  }
  &__text-secondary {
    @apply font-graphik_lv_regular;
    font-size: 12px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.5);
  }
  & ol {
    margin-left: 24px;
  }
}
.back-link {
  @apply font-graphik_lv_regular flex;
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
  & svg {
    margin-right: 2px;
  }
}
</style>

<template>
  <div class="issue-main">
    <div class="issue-main__navigation-container">
      <template v-if="issueInfo.isFinal">
        <router-link class="issue-main__navigation" :to="{ name: 'Issues' }"
          ><icon-arrow-left />К списку заданий</router-link
        >
        <div
          v-if="remained"
          class="
            flex
            text-12
            leading-16
            text-white
            items-center
            h-28px
            font-druk_text_cy
          "
        >
          <div class="mr-3">Осталось времени</div>
          <small-timer :interval="remained" />
        </div>
      </template>
      <template v-else>
        <router-link
          class="issue-main__navigation"
          :to="{ name: 'IssuesItem', params: { id: prevId } }"
          ><icon-arrow-left />Предыдущее</router-link
        >
        <router-link class="issue-main__navigation" :to="{ name: 'Issues' }"
          >К списку заданий</router-link
        >
        <router-link
          class="issue-main__navigation"
          :to="{ name: 'IssuesItem', params: { id: nextId } }"
          >Следующее<icon-arrow-right
        /></router-link>
      </template>
    </div>
    <div class="issue-main__content">
      <difficulty-value
        v-if="issueInfo.difficulty"
        :difficulty="issueInfo.difficulty"
        class="absolute top-8 right-10"
      />
      <div v-if="fields.length" class="issue-main__tabs">
        <button
          v-for="item in tabs"
          :key="item"
          class="issue-main__tabs-item"
          :class="{ current: item == currentTab }"
          @click="setTab(item)"
        >
          {{ item }}
        </button>
      </div>
      <div v-if="currentTab === 'Условие'" class="pr-32">
        <slot />
        <div class="divider" />
        <!-- <div
          v-if="issueInfo.isFinal && isEnded"
          class="text-white text-16 font-druk_text_cy"
        >
          Прием работ завершен
        </div> -->
        <!-- <template
          v-if="!issueInfo.isFinal || (issueInfo.isFinal && answerAvailable)"
        > -->
        <template>
          <template v-if="issueInfo.answerType === 'json'">
            <div class="issue-main__subtitle">Ответ в формате JSON:</div>
            <textarea v-model="answer" class="geecko-textarea" />
            <div class="issue-main__text-secondary mt-3"></div>
          </template>
          <template v-else-if="issueInfo.answerType === 'string'">
            <div class="issue-main__subtitle">Ответ:</div>
            <input
              v-model="answer"
              class="geecko-input w-full lg:w-3/5"
              :placeholder="issueInfo.placeholder"
            />
          </template>
          <template v-else-if="issueInfo.answerType === 'file'">
            <div class="issue-main__subtitle">Ответ:</div>
            <p v-if="!issueInfo.isFinal">
              Обратите внимание, что в таблице не должно быть пустых значений.
              Проверяйте файл решения через Блокнот, Notepad++ или их аналоги на
              отсутствие пустой строки в конце решения!
            </p>
            <file-input v-model="file" :label="issueInfo.inputLabel" />
          </template>
          <div class="flex items-end">
            <button
              class="btn-primary mt-8"
              :class="{ 'btn-primary--loading': answerSending }"
              :disabled="(!answer && !file) || answerSending"
              @click="
                answerIssue(
                  { task_id: taskId, data: answer, file: file },
                  issueInfo.isFinal ? issueInfo.url : 'solve'
                )
              "
            >
              {{ submitText }}
            </button>
            <slot name="result" />
          </div>
        </template>
        <!-- <div v-else class="text-white text-16 font-druk_text_cy">
          Отправка решения доступна только финалистам
        </div> -->
      </div>
      <div v-else-if="fields.length && currentTab === 'Данные'">
        <div class="issue-main__title">Описание полей</div>
        <table class="issue-main__data">
          <thead>
            <tr>
              <td>Поле</td>
              <td>Комментарий</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="{ name, comment } in fields" :key="name">
              <td>{{ name }}</td>
              <td>{{ comment }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowLeft from './Icons/IconArrowLeft.vue'
import useGame from '@/composables/useGame'
import { ref, reactive, toRefs, computed, watch } from '@vue/composition-api'
import ModalContentSuccess from './ModalContentSuccess.vue'
import ModalContentIntention from './ModalContentIntention.vue'
import DifficultyValue from './UI/DifficultyValue.vue'
import { orderedIssues, issuesDescr } from '@/composables/useGame.js'
import IconArrowRight from './Icons/IconArrowRight.vue'
import FileInput from './FileInput.vue'
import SmallTimer from './Final/SmallTimer.vue'

export default {
  name: 'IssueMain',
  components: {
    IconArrowLeft,
    ModalContentSuccess,
    ModalContentIntention,
    DifficultyValue,
    IconArrowRight,
    FileInput,
    SmallTimer,
  },
  props: {
    fields: { type: Array, default: () => [] },
    taskId: { type: String },
    issueName: { type: String, required: true },
    remained: { type: Number },
    answerAvailable: { type: Boolean, default: true },
    successTitle: { type: String, default: 'Отличная работа!' },
    successSubtitle: {
      type: String,
      default: 'Ты решил задачу, а значит, ты настоящий знаток Data Science.',
    },
    submitText: { type: String, default: 'Проверить' },
    isEnded: { type: Boolean, default: false },
  },
  data() {
    return {
      currentTab: 'Условие',
      tabs: ['Условие', 'Данные'],
    }
  },
  setup(props, ctx) {
    const answer = ref(null)
    const file = ref(null)
    const {
      answerIssue,
      answerSending,
      answerError,
      answerSuccess,
      updateUserStats,
      taskSolved,
    } = useGame()
    return {
      answer,
      answerIssue,
      answerSending,
      answerError,
      answerSuccess,
      updateUserStats,
      taskSolved,
      file,
    }
  },
  created() {},
  methods: {
    setTab(item) {
      this.currentTab = item
    },
    openSuccessModal() {
      this.$modal.show(
        ModalContentSuccess,
        {
          title: this.successTitle,
          subtitle: this.successSubtitle,
        },
        {
          name: 'success-modal',
          classes: 'success-modal',
          height: 'auto',
          width: '100%',
          clickToClose: false,
          scrollable: false,
        },
        {
          'before-open': () => {
            this.updateUserStats()
          },
          'before-close': () => {
            if (this.taskSolved === 1) {
              this.$modal.show(
                ModalContentIntention,
                {},
                {
                  name: 'intention-modal',
                  classes: 'intention-modal',
                  height: 'auto',
                  width: '625px',
                  clickToClose: false,
                  scrollable: false,
                },
                {
                  'before-open': () => {
                    this.updateUserStats()
                  },
                  'before-close': () => {},
                }
              )
            }
          },
        }
      )
    },
  },
  computed: {
    issueInfo() {
      return issuesDescr[this.issueName]
    },
    prevId() {
      const idx = orderedIssues.indexOf(this.issueName)
      const prevIdx = idx - 1
      if (prevIdx < 0) {
        return orderedIssues[orderedIssues.length - 1]
      }
      return orderedIssues[prevIdx]
    },
    nextId() {
      const idx = orderedIssues.indexOf(this.issueName)
      const nextIdx = idx + 1
      if (nextIdx > orderedIssues.length - 1) {
        return orderedIssues[0]
      }
      return orderedIssues[nextIdx]
    },
  },
  mounted() {},
  watch: {
    answerError: function (val, oldVal) {
      if (val) {
        let title = 'Неверный ответ'
        if (val?.status === 429) {
          title = 'Вы слишком часто отправляете решение. Можно раз в 10 минут'
        }
        if (val?.data?.error === 'wrong_format') {
          title = 'Неверный формат'
        }
        this.$notify({
          title,
          type: 'error',
        })
      }
    },
    answerSuccess: function (val, oldVal) {
      if (val) {
        this.openSuccessModal()
      }
    },
  },
}
</script>
