<style lang="scss">
.intention {
  @apply relative text-left;
  padding: 32px 32px 32px 245px;
  &__title {
    @apply font-druk_text_cy;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #ffffff;
  }
  &__subtitle {
    @apply font-graphik_lv_regular;
    font-size: 18px;
    line-height: 26px;
    color: #e0e0e0;
  }
  &__logo {
    @apply absolute;
    width: 189px;
    top: 20px;
    left: 43px;
  }
  &__buttons {
    @apply flex;
  }
}

.intention-thx {
  @apply font-druk_text_cy text-left;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  color: #ffffff;
  height: 42px;
}
/* -- Vue js modal -- */
.intention-modal.vm--modal {
  background-color: rgba(22, 22, 22, 1);
  border-radius: 18px;
  box-shadow: 0px 0px 15px rgba(15, 105, 210, 0.69);
}
</style>

<template>
  <div class="intention">
    <icon-logo class="intention__logo" />
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <div v-if="showContactsBlock" key="intention-contacts">
        <div class="intention__title mb-6">Контактные данные</div>
        <form @submit.prevent="sendData">
          <label class="geecko-label" for="">Телефон<span>*</span></label>
          <input id="phone" v-model="phone" class="geecko-input" type="phone" />
          <label class="geecko-label mt-4" for="">Имя</label>
          <input id="name" v-model="name" class="geecko-input" type="text" />
          <button
            class="btn-primary w-full justify-center mt-6"
            :disabled="!phone || isLoading"
            :class="{ 'btn-primary--loading': isLoading }"
            type="submit"
          >
            Отправить
          </button>
        </form>
      </div>
      <div v-else key="intention-question">
        <div class="intention__title mb-4">Хочешь работать в открытии?</div>
        <div class="intention__subtitle mb-6">
          «Открытие» находится в постоянном поиске талантливых специалистов из
          разных областей
        </div>
        <div v-if="!done" key="intention-buttons" class="intention__buttons">
          <div class="btn-primary" @click="setShowContactsBlock(true)">Да</div>
          <div class="btn-ghost" @click="respondOffer('negative')">Нет</div>
        </div>
        <div v-else key="intention-thx" class="intention-thx">
          Спасибо за ответ!
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import IconLogo from './Icons/IconLogo.vue'
import useIntention from '@/composables/useIntention'
import { ref, reactive, toRefs, computed, watch } from '@vue/composition-api'
import useUser from '../composables/useUser'

export default {
  name: 'ModalContentIntention',
  components: { IconLogo },
  data() {
    return {}
  },
  setup() {
    const { sendUserData, success: userSuccess, isLoading } = useUser()
    const { respondOffer, success } = useIntention()
    const done = ref(false)
    const showContactsBlock = ref(false)
    const name = ref(null)
    const phone = ref(null)
    const setDone = () => {
      done.value = true
    }
    const setShowContactsBlock = (value) => {
      showContactsBlock.value = value
    }
    const sendData = () => {
      sendUserData({ phone: phone.value, firstName: name.value })
    }
    return {
      respondOffer,
      success,
      done,
      setDone,
      showContactsBlock,
      setShowContactsBlock,
      name,
      phone,
      sendData,
      userSuccess,
      isLoading,
    }
  },
  props: {},
  created() {},
  mounted() {},
  methods: {
    closeModal() {
      this.$modal.hide('intention-modal')
    },
  },
  computed: {},
  watch: {
    success: function (val) {
      if (val) {
        this.$notify({
          title: 'Ответ успешно отправлен',
          type: 'success',
        })
      }
      if (val.state === 'negative') {
        setTimeout(this.closeModal(), 1000)
        this.setDone()
      }
    },
    userSuccess: function (val) {
      if (val) {
        this.$notify({
          title: 'Данные отправлены',
          type: 'success',
        })
      }
      this.setShowContactsBlock(false)
      setTimeout(this.closeModal(), 1000)
      this.setDone()
    },
  },
}
</script>
