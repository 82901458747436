<style lang="scss"></style>

<template>
  <issue-main :fields="fields" :taskId="taskId" :issueName="name">
    <div class="issue-main__title">News</div>
    <p>
      Мы решили торговать на бирже, причем так, чтобы решение о покупке акций
      принимала нейросеть на основе последних новостей о той или иной компании.
      Для этого нужно научиться классифицировать все новости. Дана база новостей
      из разных источников news.csv. Необходимо написать классифицирующую модель
      новостей по источникам.
    </p>
    <p>
      Напиши любую модель для предсказания источника новостей и предскажите
      источники для данных из файла news.csv, для которых отсутствует значение
      Source.
    </p>
    <p>
      Пороговые значения для данной задачи классификации считать равными 0.33,
      0.66.
    </p>
    <p>
      Ответ необходимо прикрепить в файле формата CSV. Файл должен содержать
      только прогнозные значения Source в одном столбце без заголовка.
    </p>
    <p class="font-bold">Для успешного прохождения достаточно иметь 70% точность решения</p>
    <button-download :file="`${taskId}.zip`" size="20.8 Mb" class="mb-12" />
  </issue-main>
</template>

<script>
import IssueMain from '../IssueMain.vue'
import FileInput from '../FileInput.vue'
import IconDownload from '../Icons/IconDownload.vue'
import useGame from '../../composables/useGame'
import Prism from 'prismjs'
import ButtonDownload from '../ButtonDownload.vue'

export default {
  name: 'IssueNews',
  components: { IssueMain, FileInput, IconDownload, ButtonDownload },
  data() {
    return {
      name: 'news',
      fields: [
        { name: 'News', comment: 'новость' },
        { name: 'Source', comment: 'источник' },
      ],
      example: `
{
  "answer": ["x1", "x2", "x3", ... , "x5"]
}
      `,
    }
  },
  props: {
    taskId: { type: String },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {
    highlightExample() {
      return Prism.highlight(
        this.example,
        Prism.languages.javascript,
        'javascript'
      )
    },
  },
}
</script>
