<style lang="scss" scoped>
ul {
  li {
    list-style: circle;
    &::marker {
    }
  }
}
.result {
  @apply flex-grow font-druk_text_cy ml-2;
  height: 42px;
  border: 1px solid rgba(74, 242, 202, 0.25);
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}
</style>

<template>
  <issue-main
    :taskId="taskId"
    :issueName="name"
    :remained="userStats && userStats.first_task_remained"
    :answerAvailable="user.is_finalist"
    :successTitle="`SCORE: ${answerSuccess && answerSuccess.result}`"
    :isEnded="finalStatus && finalStatus.is_main_task_ended"
    successSubtitle="Продолжай решать задание, чтобы улучшить свой результат"
  >
    <template v-slot:default>
      <div class="issue-main__title">
        Задача по прогнозированию ошибки<br />модели оценки вероятности дефолта
        (MPP)
      </div>
      <p>
        Мы долго работали и построили модель оценки вероятности дефолта (PD
        модель). Все работает прекрасно, но нужно контролировать качество этой
        базовой модели и оценивать ошибку, индуцированную базовой моделью,
        поэтому нам нужно разработать модель прогнозирования ошибки (MPP).
        Ошибка в нашем случае - это разница между флагом flg_90_12_add,
        представляющим собой реализованное событие (0 - не дефолт, 1 - дефолт) и
        значением PD, представляющим собой оценку от нашей базовой модели.
      </p>
      <p style="margin-bottom: 24px">
        Таким образом, Вам необходимо обучить модель прогнозирования ошибки с
        использованием двух датасетов и предсказать метрику |PD-flg_90_12_add|
        для тестовой выборки - тысячи последних экспериментов (incident), где
        значения PD и flg_90_12_add равны NaN.
      </p>
      <p style="margin-bottom: 20px">Исходные файлы:</p>

      <ul class="ml-5">
        <li style="margin-bottom: 20px">
          <span style="color: #00c1f5">data.csv </span>— данные об экспериментах
          (incident), включая большое количество признаков и флаг flg_90_12_add,
          представляющий собой реализованное событие (0 - не дефолт, 1 - дефолт)
        </li>
        <li>
          <span style="color: #00c1f5">model.csv</span> — результаты
          прогнозирования дефолта (PD), полученные в нашей разработанной модели
        </li>
        <li>
          <span style="color: #00c1f5">sample_submission.csv</span> — пример
          файла, который необходимо отправить
        </li>
      </ul>
      <p style="margin-top: 24px; margin-bottom: 24px;">
        Результаты решения прикрепите в CSV формате. Файл должен содержать
        только прогнозные значения |PD-flg_90_12_add| для тестовой выборки -
        тысячи последних экспериментов (incident), где значения PD и
        flg_90_12_add равны NaN, в одном столбце без заголовка. В качестве
        оценки решения будет использоваться характеристика MAE.
      </p>
      <button-download
        fileLink="https://cdn.geecko.com/cdn-back-prod/open-data-battle/mpp.zip"
        file="opendata.zip"
        size="50 Mb"
        class="mb-12"
        :taskId="name"
      />
      <p class="font-bold">
        Решения можно отправлять неоднократно, фиксироваться будет наилучшее.
      </p>
      <p class="font-bold">Отправить решение можно раз в 10 минут.</p>
      <p class="font-bold">Скорость решения не учитывается.</p>
    </template>
    <template v-slot:result
      ><div
        v-if="user.main_task_result"
        class="result flex items-center justify-center"
      >
        <span style="color: #4af2ca">Результат:&nbsp;&nbsp;</span
        >{{ user.main_task_result }}
      </div></template
    >
  </issue-main>
</template>

<script>
import useGame from '@/composables/useGame'
import IssueMain from '../IssueMain.vue'
import FileInput from '../FileInput.vue'
import IconDownload from '../Icons/IconDownload.vue'
import Prism from 'prismjs'
import ButtonDownload from '../ButtonDownload.vue'
import useUser from '@/composables/useUser'

export default {
  name: 'IssueNews',
  components: { IssueMain, FileInput, IconDownload, ButtonDownload },
  data() {
    return {
      name: 'mpp',
    }
  },
  setup(props, ctx) {
    const { finalStatus, answerSuccess, loadUserStats, userStats } = useGame()
    const { user } = useUser()
    loadUserStats()
    return {
      answerSuccess,
      userStats,
      user,
      finalStatus,
    }
  },
  props: {
    taskId: { type: String },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
