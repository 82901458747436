<style lang="scss"></style>

<template>
  <button
    class="btn-icon"
    :class="{ success: copied, 'animate-spin': copied }"
    @click="copyText"
  >
    <component :is="copied ? 'icon-check' : 'icon-copy'" />
  </button>
</template>

<script>
import IconCheck from './Icons/IconCheck.vue'
import IconCopy from './Icons/IconCopy.vue'
export default {
  name: 'ButtonCopyText',
  components: { IconCopy, IconCheck },
  data() {
    return {
      copied: false,
    }
  },
  props: {
    text: { type: String, required: true },
  },
  created() {},
  mounted() {},
  methods: {
    copyText() {
      navigator.clipboard
        .writeText(this.text)
        .then(() => {
          this.copied = true
          this.$notify({
            title: 'Текст скопирован',
            type: 'success',
          })
          setTimeout(() => (this.copied = false), 1000)
        })
        .catch((err) => {
          this.copied = true
          this.$notify({
            title: 'Ошибка при копировании',
            type: 'error',
          })
        })
    },
  },
  computed: {},
}
</script>
