<style lang="scss" scoped>
.answer-type {
  font-family: GraphikLC Regular;
  font-size: 12px;
  display: inline-block;
  padding: 15px;
  color: white;
  border: 1px solid hsl(212deg 18% 82% / 19%);
  margin-bottom: 25px;
  max-width: 290px;
}
</style>

<template>
  <issue-main :fields="fields" :taskId="taskId" :issueName="name">
    <div class="issue-main__title">Paris</div>
    <p>Дана выборка недвижимости в Париже в файле ParisHousing.csv</p>
    <button-download :file="`${taskId}.zip`" size="323 Kb" class="mb-12" />
    <p>
      Необходимо определить оптимальное число кластеров для данной выборки при
      помощи метода kMeans с использованием метода “локтя” с шагом 20
    </p>
    <div class="answer-type">
      Обратите внимание, что шаг равен 20-ти. Допускаются такие ответы как 60, 120, 140…
    </div>
  </issue-main>
</template>

<script>
import IssueMain from '../IssueMain.vue'
import FileInput from '../FileInput.vue'
import IconDownload from '../Icons/IconDownload.vue'
import useGame from '../../composables/useGame'
import Prism from 'prismjs'
import ButtonDownload from '../ButtonDownload.vue'

export default {
  name: 'IssueParis',
  components: { IssueMain, FileInput, IconDownload, ButtonDownload },
  data() {
    return {
      name: 'paris',
      fields: [
        { name: 'squareMeters', comment: 'размер дома в квадратных метрах' },
        { name: 'numberOfRooms', comment: 'количество комнат в доме' },
        { name: 'hasYardPool', comment: 'есть ли в доме двор и бассейн' },
        { name: 'floors', comment: 'сколько там этажей' },
        { name: 'cityCode', comment: 'номер почтового индекса' },
        {
          name: 'cityPartRange',
          comment: 'диапазон: 0- самый дешевый, 10 - самый дорогой',
        },
        { name: 'numPrevOwners', comment: 'количество прежних владельцев' },
        { name: 'made', comment: 'год строительства' },
        { name: 'isNewBuilt', comment: 'это новый или отремонтированный' },
        { name: 'hasStormProtector', comment: 'имеет ли защиту от бури' },
        { name: 'basement', comment: 'наличие подвала' },
        { name: 'attic', comment: 'наличие чердака' },
        { name: 'garage', comment: 'наличие гаража' },
        { name: 'hasStorageRoom', comment: 'наличие кладовой' },
        { name: 'hasGuestRoom', comment: 'наличие гостевой комнаты' },
        { name: 'price', comment: 'цена' },
      ],
      example: `
        {
          "answer": ["x1", "x2", "x3", ... , "x5"]
        }
      `,
    }
  },
  props: {
    taskId: { type: String },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {
    highlightExample() {
      return Prism.highlight(
        this.example,
        Prism.languages.javascript,
        'javascript'
      )
    },
  },
}
</script>
