<style lang="scss" scoped>
ul {
  li {
    list-style: circle;
    &::marker {
    }
  }
}
</style>

<template>
  <issue-main
    :fields="fields"
    :taskId="taskId"
    :issueName="name"
    :remained="userStats && userStats.second_task_remained"
    :answerAvailable="user.is_finalist"
    successTitle="Решение отправлено"
    successSubtitle="Победители будут объявлены 31 октября во время трансляции"
    submitText="Отправить"
    :isEnded="finalStatus && finalStatus.is_essay_task_ended"
  >
    <div class="issue-main__title">
      Найди ошибки в построенной модели<br />прогнозирования факта сдачи теста
    </div>
    <p style="margin-bottom: 24px">
      Вы пришли на новую работу в качестве специалиста по Data Science. Ваше
      первое задание — разобраться с моделью, находящейся в архиве tests.zip.
      Говорят, что эта модель раньше работала, но что-то пошло не так. Вам нужно
      найти ошибки в модели и их исправить. Напишите эссе в свободном виде с
      описанием ошибок и методов их решения/устранения.
    </p>
    <p style="margin-bottom: 20px">В архиве вам будут нужны 2 файла:</p>
    <ul class="ml-5">
      <li style="margin-bottom: 20px">
        <span style="color: #00c1f5">dataset.csv</span> — данные о фактах
        сдачи/несдачи теста учениками;
      </li>
      <li>
        <span style="color: #00c1f5">Model_students_upd.ipynb</span> — модель в
        формате Jupyter Notebook, которую требуется проанализировать.
      </li>
    </ul>
    <button-download
      :file="`${taskId}.zip`"
      size="237 Kb"
      class="mb-12"
      :taskId="name"
    />
    <p class="font-bold">
      Эссе можно отправлять неоднократно, фиксироваться будет последний вариант.
    </p>
    <p class="font-bold">
      Задание проверяется вручную судьей. Результаты будут оглашены на
      трансляции 31 октября.
    </p>
  </issue-main>
</template>

<script>
import useGame from '@/composables/useGame'
import IssueMain from '../IssueMain.vue'
import FileInput from '../FileInput.vue'
import IconDownload from '../Icons/IconDownload.vue'
import Prism from 'prismjs'
import ButtonDownload from '../ButtonDownload.vue'
import useUser from '@/composables/useUser'

export default {
  name: 'IssueNews',
  components: { IssueMain, FileInput, IconDownload, ButtonDownload },
  data() {
    return {
      name: 'exam',
      fields: [
        { name: 'school', comment: 'код школы, в которой учится ученик' },
        {
          name: 'school_setting',
          comment: 'тип района в котором находится школа',
        },
        { name: 'school_type', comment: 'тип школы: частная или нет' },
        {
          name: 'classroom',
          comment: 'внутренний код класса, в котором проходят занятия',
        },
        {
          name: 'teaching_method',
          comment: 'является ли обучение экспериментальным',
        },
        { name: 'n_student', comment: 'возраст ученика' },
        { name: 'gender', comment: 'пол ученика' },
        {
          name: 'lunch',
          comment: 'является ли ученик слабозащищенной категорией населения',
        },
        { name: 'posttest', comment: 'балл по тесту' },
        { name: 'target', comment: 'сдал или нет. 1 — сдал' },
      ],
    }
  },
  setup(props, ctx) {
    const { finalStatus, loadUserStats, userStats } = useGame()
    const { user } = useUser()
    loadUserStats()
    return {
      userStats,
      user,
      finalStatus,
    }
  },
  props: {
    taskId: { type: String },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
