<style lang="scss">
.issues-item-content {
  @apply mx-auto;
  padding-top: 32px;
  padding-bottom: 82px;
  max-width: 1000px;
}
</style>

<template>
  <div class="issues-item-content">
    <component :is="`issue-${$route.params.id}`" :taskId="$route.params.id" />
  </div>
</template>

<script>
import IssueMain from '../../components/IssueMain.vue'
import IssuePredict from '../../components/Issues/IssuePredict.vue'
import IssueNews from '../../components/Issues/IssueNews.vue'
import IssueCovid19_1 from '../../components/Issues/IssueCovid19_1.vue'
import IssueParis from '../../components/Issues/IssueParis.vue'
import IssueAirport from '../../components/Issues/IssueAirport.vue'
import IssueGender from '../../components/Issues/IssueGender.vue'
import IssueMpp from '../../components/Issues/IssueMpp.vue'
import IssueExam from '../../components/Issues/IssueExam.vue'

export default {
  name: 'IssuesItem',
  components: {
    IssueMain,
    IssuePredict,
    IssueNews,
    IssueCovid19_1,
    IssueParis,
    IssueAirport,
    IssueGender,
    IssueMpp,
    IssueExam,
  },
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
