<style lang="scss"></style>

<template>
  <issue-main :fields="fields" :taskId="taskId" :issueName="name">
    <div class="issue-main__title">Gender</div>
    <p>
      В одной компании произошла неприятность: случился потоп, в бумажных
      анкетах клиентов размыло некоторые строки и в 1000 анкетах пострадало поле
      “gender”. Конечно, их было бы можно восстановить, но в компании решили
      автоматизировать решение проблемы и определить пол клиентов по
      фотографиям. Был создан датасет gender.csv, в котором записаны признаки
      человека на фото и его пол.
    </p>
    <p>Первые 1000 значений имеют пропуски в столбце gender.</p>
    <p>
      Все столбцы с ".1" в названии дублируют предыдущие столбцы без ".1", но
      содержат уникальные значения
    </p>
    <p>Необходимо произвести чистку данных:</p>
    <ol>
      <li>Присоединить дублирующие столбцы к основным.</li>
      <li>
        Столбцы 'nose_wide', 'nose_long', 'lips_thin',
        'distance_nose_to_lip_long', 'long_hair' содержат информацию в условных
        единицах. Известно, что измерения проводились разными людьми с разными
        измерительными инструментами, поэтому данные в столбцах имеют разную
        погрешность измерений. Необходимо уменьшить влияние погрешности путем
        приравнивания значений диапазона [0,0.5) к 0, а [0.5, 1) —- к 1. Столбцы
        'orehead_width_cm' и 'forehead_height_cm' необходимо оставить «как
        есть».
      </li>
      <li>Оставить один из дублирующих друг друга столбцов.</li>
    </ol>
    <p>
      Определите пол по остальным параметрам из выборки для первой тысячи
      значений и прикрепите его в CSV формате. Файл должен содержать только
      прогнозные значения в формате "Male"/"Female", в одном столбце без
      заголовка
    </p>
    <p>
      Пороговое значение для данной задачи классификации считать равным 0.5.
    </p>
    <p class="font-bold">Для успешного прохождения достаточно иметь 70% точность решения</p>
    <button-download :file="`${taskId}.zip`" size="276 Kb" class="mb-12" />
  </issue-main>
</template>

<script>
import IssueMain from '../IssueMain.vue'
import FileInput from '../FileInput.vue'
import IconDownload from '../Icons/IconDownload.vue'
import useGame from '../../composables/useGame'
import Prism from 'prismjs'
import ButtonDownload from '../ButtonDownload.vue'

export default {
  name: 'IssueGender',
  components: { IssueMain, FileInput, IconDownload, ButtonDownload },
  data() {
    return {
      name: 'gender',
      fields: [
        { name: 'index', comment: 'индексы записей' },
        { name: 'long_hair', comment: 'длинные волосы у человека или нет' },
        {
          name: 'forehead_width_cm',
          comment: 'ширина лба справа налево в см.',
        },
        {
          name: 'forehead_height_cm',
          comment: 'ширина лба в см от места роста волос до бровей.',
        },
        {
          name: 'forehead_width_mm',
          comment: 'ширина лба справа налево в мм.',
        },
        {
          name: 'forehead_width_conventional_units',
          comment:
            'ширина лба справа налево в стандартизованном виде. Значения forehead_width_mm преобразованы в диапазон от 0 до 1.',
        },
        {
          name: 'nose_wide',
          comment: 'широкий нос или нет. 1 означает широкий, а 0 - нет.',
        },
        {
          name: 'nose_long',
          comment: 'длинный нос или нет. 1 означает длинный, а 0 - нет.',
        },
        {
          name: 'lips_thin',
          comment:
            'есть ли у этого человека тонкая губа или нет. 1 означает тонкая, а 0 - нет.',
        },
        {
          name: 'distance_nose_to_lip_long',
          comment:
            'расстояние от носа до губы. 1 означает большое, а 0 - небольшое.',
        },
        {
          name: 'gender',
          comment: 'Либо мужчина ("Male"), либо женщина ("Female")',
        },
      ],
      example: `
{
  "answer":["Female", "Female", "Female", ... , "Female"]
}
      `,
    }
  },
  props: {
    taskId: { type: String },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {
    highlightExample() {
      return Prism.highlight(
        this.example,
        Prism.languages.javascript,
        'javascript'
      )
    },
  },
}
</script>
