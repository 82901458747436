<style lang="scss"></style>

<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0767 2.21993C10.4699 1.27463 10.6664 0.80198 11 0.80198C11.3336 0.80198 11.5301 1.27463 11.9233 2.21993L13.9395 7.06735C14.0537 7.34189 14.1108 7.47917 14.2245 7.56182C14.3383 7.64448 14.4865 7.65636 14.7829 7.68012L20.0161 8.09966C21.0366 8.18148 21.5469 8.22238 21.65 8.53962C21.753 8.85686 21.3643 9.18988 20.5867 9.85592L16.5996 13.2713C16.3738 13.4648 16.2608 13.5615 16.2174 13.6952C16.1739 13.829 16.2084 13.9736 16.2774 14.2628L17.4956 19.3695C17.7331 20.3654 17.8519 20.8633 17.582 21.0594C17.3122 21.2555 16.8753 20.9886 16.0016 20.455L11.5213 17.7184C11.2675 17.5634 11.1406 17.4859 11 17.4859C10.8594 17.4859 10.7325 17.5634 10.4787 17.7184L5.9984 20.455C5.12468 20.9886 4.68783 21.2555 4.41797 21.0594C4.14811 20.8633 4.26689 20.3654 4.50444 19.3695L5.72257 14.2628C5.79157 13.9736 5.82606 13.829 5.78261 13.6952C5.73915 13.5615 5.62624 13.4648 5.40042 13.2713L1.41328 9.85592C0.635741 9.18988 0.246974 8.85686 0.35005 8.53962C0.453127 8.22238 0.963392 8.18148 1.98392 8.09966L7.21712 7.68012C7.51351 7.65636 7.66171 7.64448 7.77547 7.56182C7.88924 7.47917 7.94633 7.34189 8.06052 7.06735L10.0767 2.21993Z"
      fill="#00C1F5"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconStar',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
