<style lang="scss"></style>

<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66608 7.33338H9.72608L7.52608 5.14004C7.40055 5.01451 7.33002 4.84424 7.33002 4.66671C7.33002 4.48917 7.40055 4.31891 7.52608 4.19338C7.65162 4.06784 7.82188 3.99731 7.99942 3.99731C8.17695 3.99731 8.34722 4.06784 8.47275 4.19338L11.8061 7.52671C11.8668 7.59011 11.9144 7.66487 11.9461 7.74671C12.0128 7.90902 12.0128 8.09107 11.9461 8.25338C11.9144 8.33521 11.8668 8.40997 11.8061 8.47338L8.47275 11.8067C8.41078 11.8692 8.33704 11.9188 8.2558 11.9526C8.17456 11.9865 8.08743 12.0039 7.99942 12.0039C7.91141 12.0039 7.82427 11.9865 7.74303 11.9526C7.66179 11.9188 7.58806 11.8692 7.52608 11.8067C7.4636 11.7447 7.414 11.671 7.38016 11.5898C7.34631 11.5085 7.32889 11.4214 7.32889 11.3334C7.32889 11.2454 7.34631 11.1582 7.38016 11.077C7.414 10.9958 7.4636 10.922 7.52608 10.86L9.72608 8.66671H4.66608C4.48927 8.66671 4.3197 8.59647 4.19468 8.47145C4.06966 8.34642 3.99942 8.17685 3.99942 8.00004C3.99942 7.82323 4.06966 7.65366 4.19468 7.52864C4.3197 7.40361 4.48927 7.33338 4.66608 7.33338Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconArrowRight',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
