<style lang="scss">
.issue-card {
  &__difficulty {
    @apply w-max;
    font-size: 12px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.8);
    &-stars {
      @apply flex mt-1;
    }
  }
}
</style>

<template>
  <div class="issue-card__difficulty">
    Сложность
    <div class="issue-card__difficulty-stars">
      <icon-star v-for="i in difficulty" :key="`star-${i}`" />
      <icon-star-outline
        v-for="i in 3 - difficulty"
        :key="`star-otline-${i}`"
      />
    </div>
  </div>
</template>

<script>
import IconStar from '@/components/Icons/IconStar.vue'
import IconStarOutline from '@/components/Icons/IconStarOutline.vue'

export default {
  name: 'DifficultyValue',
  components: { IconStar, IconStarOutline },
  data() {
    return {}
  },
  props: {
    difficulty: {
      type: Number,
      validator: function (value) {
        return [1, 2, 3].indexOf(value) !== -1
      },
    },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
