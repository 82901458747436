<style lang="scss"></style>

<template>
  <issue-main :taskId="taskId" :issueName="name">
    <div class="issue-main__title">Predict</div>
    <p>
      В одной из компаний для найма сотрудников решили создать математическую
      модель, описывающую зависимость уровня заработной платы от опыта
      сотрудников. В файлах ‘inputs.npy’, ‘outputs.npy’ даны оценки заработной
      платы и опыта сотрудников, представленные в своих диапазонах и полученные
      в результате использования шкалы перевода.
    </p>
    <p>
      В компании разработали простейшую нейронную сеть model с ошибкой loss.
      Обучи нейронную сеть предсказывать необходимые значения. В качестве
      ответа к заданию предскажи 5 точек [1, 2, 3, 4, 5] при помощи нейронной
      сети любой модели. Ответ выдайте в формате JSON:
    </p>
    <div class="issue-main__code-block">
      <pre><code class="language-javascript" v-html="highlightExample"></code></pre>
    </div>
    <button-download :file="`${taskId}.zip`" size="8 Kb" class="mb-12" />

    <div class="issue-main__code-block">
      <button-copy-text
        class="issue-main__code-block__copy-button"
        :text="code"
      />
      <pre><code class="language-javascript" v-html="highlightCode"></code></pre>
    </div>
  </issue-main>
</template>

<script>
import IssueMain from '../IssueMain.vue'
import FileInput from '../FileInput.vue'
import { highlight, languages } from 'prismjs/components/prism-core'
import Prism from 'prismjs'
import ButtonCopyText from '../ButtonCopyText.vue'
import ButtonDownload from '../ButtonDownload.vue'

export default {
  name: 'IssueFirst',
  components: { IssueMain, FileInput, ButtonCopyText, ButtonDownload },
  data() {
    return {
      name: 'predict',
      file: null,
      code: `
import tensorflow as tf
import numpy as np
import matplotlib.pyplot as plt
# Модель
class myModel(object):
  def __init__(self):
    self.W = tf.Variable(5.0)
    self.b = tf.Variable(10.0)

  def __call__(self, x):
    return self.W * x + self.b

model = myModel()

# Ошибка
def loss(target_y, predicted_y):
  return tf.reduce_mean(tf.square(target_y - predicted_y))

print('Current loss: %1.6f' % loss(model(inputs), outputs).numpy())
Current loss: 64.301849
`,
      example: `
{
  "answer": ["x1", "x2", "x3", ... , "x5"]
}
      `,
    }
  },
  props: {
    taskId: { type: String },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {
    highlightCode() {
      return Prism.highlight(
        this.code,
        Prism.languages.javascript,
        'javascript'
      )
    },
    highlightExample() {
      return Prism.highlight(
        this.example,
        Prism.languages.javascript,
        'javascript'
      )
    },
  },
}
</script>
